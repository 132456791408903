@import 'partials/variables';

#navigationFirstLevelMenu ul li#nav_74 ul {
	left: -510px;
}

#navigationFirstLevelMenu ul li ul {
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='#{$img-path}/nav_bg.png', sizingMethod='scale');
}

#navigationFirstLevelMenu ul li ul li ul {
	filter: none;
}
